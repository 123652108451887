import React, { useState } from "react"
import "./index.scss"
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"

const CarouselFilter = ({ options, selectedOption, onOptionChange }) => {
    const [currentOptionIndex, setCurrentOptionIndex] = useState(options.indexOf(selectedOption))
    const initialStartIndex = Math.max(0, Math.min(options.indexOf(selectedOption) - 1, options.length - 3))
    const [startIndex, setStartIndex] = useState(initialStartIndex)

    const handlePrevClick = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1)
            setCurrentOptionIndex(currentOptionIndex - 1)
            onOptionChange(options[currentOptionIndex - 1])
        }
    }

    const handleNextClick = () => {
        if (startIndex < options.length - 3) {
            setStartIndex(startIndex + 1)
            setCurrentOptionIndex(currentOptionIndex + 1)
            onOptionChange(options[currentOptionIndex + 1])
        }
    }


    return (
        <div className="carousel-filter">
            <button
                className="carousel-filter-arrow"
                onClick={handlePrevClick}
                disabled={startIndex === 0}
            >
                <ArrowBackIosRoundedIcon />
            </button>

            <div className="carousel-filter-options">
                {options.slice(startIndex, startIndex + 3).map((option, index) => (
                    <span
                        key={startIndex + index}
                        className={`carousel-filter-option t-s4 ${startIndex + index === currentOptionIndex ? "selected" : ""}`}
                        onClick={() => {
                            setCurrentOptionIndex(startIndex + index)
                            onOptionChange(option)
                        }}
                    >
                        {option}
                    </span>
                ))}
            </div>

            <button
                className="carousel-filter-arrow"
                onClick={handleNextClick}
                disabled={startIndex >= options.length - 3}
            >
                <ArrowForwardIosRoundedIcon />
            </button>
        </div>
    )
}

export default CarouselFilter
