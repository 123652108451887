import React from "react"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"

const CalendarEventItem = ({event}) => {
    return (
        <div className="events-side-panel-content-event">
            <SingleDatePicker
                defaultValue={event.date}
                className="events-side-panel-content-event-date-picker"
                onChange={(date) => console.log(date)}
                years={false}
                calendarPosition="bottom-left"
            />
            <TextInput
                placeholder="Name of the event"
                value={event.name}
            />
        </div>
    )
}

export default CalendarEventItem