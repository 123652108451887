import "./index.scss"

import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"
import Helmet from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {
    receiveNextPagePerformanceReviews,
    receiveNextPageUsersPerformanceReviews,
    receivePerformanceReviews,
    receiveUsersPerformanceReviews,
} from "./actions/performanceReviewsActions"

import Protected from "../../components/Protected/Protected"
import PerformanceReviewsTable from "./components/PerformanceReviewsTable"
import CreatePerformanceReviewPopup from "./components/CreatePerformanceReviewPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"
import {useLocation} from "react-router-dom"


const Page = () => {
    const location = useLocation()
    
    const dispatch = useDispatch()

    const {hasPermission} = useSelector((state) => state.profile)

    const {
        tableLoading,
        performanceReviews,
        total,
    } = useSelector((state) => state.performanceReviews)

    const userId = location.state ? location.state.userId : null

    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState(location.state?.username ? location.state.username : undefined)
    const [addPerformanceReviewPopup, setAddPerformanceReviewPopup] = useState(false)
    const [loadCurrentUserReviews, setLoadCurrentUserReviews] = useState(!location.state?.username)

    const getPerformanceReviews = (page) => {
        const params = {
            page: page || currentPage,
            size: PAGE_SIZE,
        }
        const paramsExt = {
            ...params,
            user_full_name: searchKey,
        }

        dispatch(
            hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW)
                ? receivePerformanceReviews(paramsExt)
                : receiveUsersPerformanceReviews(userId, params)
        )
        setCurrentPage(page + 1)
    }

    const getNextPerformanceReviews = () => {
        const params = {
            page: currentPage,
            size: PAGE_SIZE,
        }
        const paramsExt = {
            ...params,
            user_full_name: searchKey,
        }

        dispatch(
            hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW)
                ? receiveNextPagePerformanceReviews(paramsExt)
                : receiveNextPageUsersPerformanceReviews(userId, params)
        )
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getPerformanceReviews(1)
        if (!loadCurrentUserReviews) {
            setLoadCurrentUserReviews(true)
        }
    }, [])

    useEffect(() => {
        if (searchKey !== undefined) {
            getPerformanceReviews(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title> Performance Reviews - Newsoft Inside </title>
            </Helmet>
            <div className="performance-reviews">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className={`performance-reviews-header${!hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) ? "-user" : ""}`}>
                    <Protected permissions={[PERMISSIONS.READ_PERFORMANCE_REVIEW]}>
                        <div className="performance-reviews-header-search">
                            <SearchForm onChange={setSearchKey} debounceMs={500} />
                        </div>
                        <Protected permissions={[PERMISSIONS.WRITE_PERFORMANCE_REVIEW]}>
                            <div className="performance-reviews-header-button">
                                <SmallButton onClick={() => {setAddPerformanceReviewPopup(true)}}> ADD PERFORMANCE REVIEW </SmallButton>
                            </div>
                        </Protected>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextPerformanceReviews}
                    hasMore={!tableLoading && performanceReviews.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={performanceReviews.length}
                    scrollableTarget="main-content"
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="performance-reviews-content">
                            <PerformanceReviewsTable
                                performanceReviews={performanceReviews}
                                refreshTable={() => getPerformanceReviews(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addPerformanceReviewPopup} closeOnDocumentClick={false} onClose={() => setAddPerformanceReviewPopup(false)} modal>
                {close => (
                    <CreatePerformanceReviewPopup
                        refreshTable={() => getPerformanceReviews(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default Page
