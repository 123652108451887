import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useSetOOOCalendarMutation} from "../../../api/googleIntegration"


const ChangeOOOCalendarPopup = ({calendarId, calendarName, setUserMessage, close}) => {
    const [setOOOCalendar, {isLoading: calendarChangeLoading}] = useSetOOOCalendarMutation()

    const handleSubmit = async () => {
        try {
            await setOOOCalendar(calendarId).unwrap()
            setUserMessage({message: "Calendar changed", code: SUCCESS_CODE})
            close()
        } catch (error) {
            setUserMessage({message: error.message, code: ERROR_CODE})
        }
    }

    return (
        <CustomPopup text_title={`Do you want to select "${calendarName}" as OOO calendar?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={calendarChangeLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={calendarChangeLoading} onClick={handleSubmit}> SELECT </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default ChangeOOOCalendarPopup
