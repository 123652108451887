import "./index.scss"

import React, {useState} from "react"
import Helmet from "react-helmet"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import TimeRecordsRequestsTable from "./components/TimeRecordsTable"
import {useLocation} from "react-router-dom"

const TimeRecordsRequests = () => {
    const location = useLocation()
    const [userMessage, setUserMessage] = useState(null)

    return (
        <>
            <Helmet>
                <title>Time Record Requests - Newsoft Inside </title>
            </Helmet>
            <div className="time-records-requests">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                <TimeRecordsRequestsTable location={location} setUserMessage={setUserMessage} />
            </div>
        </>
    )
}

export default TimeRecordsRequests
