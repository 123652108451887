import "./index.scss"

import {React, useState} from "react"
import {useSelector} from "react-redux"
import Helmet from "react-helmet"
import CalendarCard from "./components/CalendarCard"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetPublicHolidayCalendarsQuery} from "../../api/publicHolidayCalendars"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import InfiniteScroll from "react-infinite-scroll-component"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {PERMISSIONS} from "../../constants/permissions"
import Protected from "../../components/Protected/Protected"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import EventsSidePanel from "./components/EventsSidePanel"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

const PublicHolidayCalendars = () => {
    const { items: calendars, loading, hasMore, loadNextPage } = usePaginatedQuery(useGetPublicHolidayCalendarsQuery)
    const {hasPermission} = useSelector((state) => state.profile)
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
    const [selectedCalendar, setSelectedCalendar] = useState(null)
    const [userMessage, setUserMessage] = useState(null)

    const handleCalendarClick = (calendar) => {
        setSelectedCalendar(calendar)
        setIsSidePanelOpen(true)
    }

    const handleClosePanel = () => {
        setIsSidePanelOpen(false)
    }

    return (
        <>
            <Helmet>
                <title> Public Holiday Calendars - Newsoft Inside </title>
            </Helmet>
            <div>
                {userMessage &&
                    <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                }
            </div>
            <div className="calendars">
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={calendars.length}
                    scrollableTarget={"main-content"}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="calendars-content">
                            {calendars.map((calendar) =>
                                <CalendarCard
                                    key={calendar.id}
                                    calendar={calendar}
                                    onClick={() => handleCalendarClick(calendar)}
                                />
                            )}
                            <Protected permissions={[PERMISSIONS.WRITE_CALENDAR_EVENTS]}>
                                <div className="calendars-content-card add-calendar">
                                    <AddRoundedIcon/>
                                </div>
                            </Protected>
                            {!loading && calendars.length === 0 && !hasPermission(PERMISSIONS.WRITE_CALENDAR_EVENTS) && <EmptyResult/>}
                        </div>
                    }
                </InfiniteScroll>
                <EventsSidePanel
                    calendar={selectedCalendar}
                    isOpen={isSidePanelOpen}
                    onClose={handleClosePanel}
                    setUserMessage={setUserMessage}
                    loading={loading}
                />
            </div>
        </>
    )
}

export default PublicHolidayCalendars
