import React, {useMemo, useState} from "react"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"

import {generateCancelToken} from "../../../http"

import {useCustomHistory} from "../../../hooks/useCustomHistory"

import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import UserImage from "../../../assets/images/user_photo.png"

import DeleteTimeRecordRequestPopup from "./DeleteTimeRecordRequestPopup"
import EditTimeRecordPopup from "../../TimeTracker/components/EditTimeRecordPopup"
import InfoPopup from "../../../components/InfoPopup"

import {formatDate, minutesToString} from "../../../utils/datetime.utils"
import {canEditTimeRecord} from "../../../utils/permission.utils"

import {
    DD_MM_YYYY,
    ERROR_CODE,
    HOURS_TYPE_ONLY_TIME_OFF_OPTIONS,
    SUCCESS_CODE,
    TIME_RECORD_STATUS,
} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import {useUpdateTimeRecordMutation} from "../../../api/timeRecords"
import Table from "../../../components/Table/Table"
import {getUserViewedAs} from "../../../utils/localstorage.utils"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import useTimeRecords from "../../../hooks/useTimeRecords"


const TimeRecordsRequestsTable = ({setUserMessage, location}) => {
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : location.state?.userId

    const params = useMemo(() => ({
        all: true,
        status_in: TIME_RECORD_STATUS.IN_REVIEW,
    }), [])

    const {
        loading,
        items,
        hasMore,
        loadNextPage,
        reset,
    } = usePaginatedQuery(useTimeRecords, {defaultState: {params: {userId, ...params}}})

    const [approvedRecords, setApprovedRecords] = useState([])

    const [updateTimeRecord] = useUpdateTimeRecordMutation()
    const history = useCustomHistory()

    const dispatch = useDispatch()

    const [cancelToken, setCancelToken] = useState(null)

    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)

    const isMyProfile = auth.user.uid === userId

    const timeOffHoursTypes = HOURS_TYPE_ONLY_TIME_OFF_OPTIONS.map(option => option.value)

    const profileClick = (id) => history.push(`/profile/${id}`)

    const approveTimeRecord = (timeRecordId, isApprove, event) => {
        event.target.disabled = true
        const data = {
            status: isApprove === true ? TIME_RECORD_STATUS.APPROVED : TIME_RECORD_STATUS.DECLINED
        }

        updateTimeRecord({id: timeRecordId, data})
            .unwrap()
            .then(() => {
                setApprovedRecords(state => [...state, timeRecordId])
                setUserMessage({
                    message: `Your time record was ${isApprove ? "approved" : "declined"}!`,
                    code: SUCCESS_CODE,
                })
                dispatch(
                    receiveNotifications(generateCancelToken(cancelToken, setCancelToken))
                )
            })
            .catch(error => {
                setUserMessage({message: error.message || "An unexpected error occurred", code: ERROR_CODE})
                setApprovedRecords(state => state.filter(id => id !== timeRecordId))
            }).finally(() => {
                event.target.disabled = false
            })
    }

    const columns = [
        {
            field: "id",
            isKey: true,
            hidden: true
        },
        {
            field: "id",
            title: "EMPLOYEE",
            get: (row) => (
                <div className="time-records-requests-content-employee custom-scrollable-table-employee">
                    <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(row.user.id)}>
                        <img src={row.user?.image_url ? row.user.image_url : UserImage} alt="employee"/>
                    </span>
                    <span className="time-records-requests-content-employee-name custom-scrollable-table-employee-name clickable" onClick={() => profileClick(row.user.id)}>
                        {row.user?.first_name} {row.user?.last_name}
                    </span>
                </div>
            )
        },
        {
            field: "date",
            title: "DATE",
            get: (row) => formatDate(row.date, DD_MM_YYYY),
        },
        {
            field: "hours_type",
            title: "HOURS TYPE",
        },
        {
            field: "project",
            title: "PROJECT",
            get: (row) => row.project?.name,
        },
        {
            field: "duration",
            title: "Duration",
            get: (row) => minutesToString(row.duration),
        },
        {
            field: "note",
            title: "Note",
            custom: true,
            get: (row) => (
                <Popup trigger={<span className="cell-limited-text clickable"> {row.note} </span>} modal>
                    {close => (
                        <InfoPopup
                            title="Note"
                            value={row.note}
                            close={close}
                        />
                    )}
                </Popup>
            )
        },
        {
            field: "actions",
            title: "ACTIONS",
            get: (row) => {
                if (isMyProfile && hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && canEditTimeRecord(row.date)) {
                    return (
                        <>
                            {!timeOffHoursTypes.includes(row.hours_type) && row?.status !== TIME_RECORD_STATUS.IN_REVIEW &&
                                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                                    {close => (
                                        <EditTimeRecordPopup
                                            timeRecord={row}
                                            refreshData={reset}
                                            setUserMessage={setUserMessage}
                                            close={close}
                                            userId={userId}
                                        />
                                    )}
                                </Popup>
                            }
                            {(hasPermission(PERMISSIONS.WRITE_TIME_RECORD) ||
                                    (hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && isMyProfile && row?.status === TIME_RECORD_STATUS.IN_REVIEW) ||
                                    !timeOffHoursTypes.includes(row.hours_type)) &&
                                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                                    {close => (
                                        <DeleteTimeRecordRequestPopup
                                            timeRecord={row}
                                            refreshData={reset}
                                            setUserMessage={setUserMessage}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                            }
                        </>
                    )
                } else if (!isMyProfile && hasPermission(PERMISSIONS.WRITE_TIME_RECORD)) {
                    return (
                        <>
                            <button
                                className="time-records-requests-content-actions custom-scrollable-table-link"
                                onClick={(event) => approveTimeRecord(row.id, false, event)}
                            >
                                {"decline"}
                            </button>

                            <button
                                className="time-records-requests-content-actions custom-scrollable-table-link"
                                onClick={(event) => approveTimeRecord(row.id, true, event)}
                            >
                                {"approve"}
                            </button>
                        </>
                    )
                } else {
                    return null
                }
            },
            type: "custom"
        },
    ]


    return <Table
        items={items.filter(record => !approvedRecords.includes(record.id))}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        setUserMessage={setUserMessage}
    />
}

export default TimeRecordsRequestsTable
