import "./index.scss"

import React, {useEffect, useMemo, useState} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import {useSelector} from "react-redux"
import {PERMISSIONS} from "../../constants/permissions"
import {
    useGetGoogleCalendarIntegrationQuery,
    useGetGoogleCalendarsQuery,
    useRemoveGoogleCalendarIntegrationMutation,
    useSetupGoogleCalendarIntegrationMutation
} from "../../api/googleIntegration"
import {ERROR_CODE, SUCCESS_CODE} from "../../constants/other"
import DDList from "../../toolkits/DDList/DDList"
import Card from "../../components/containers/Card"
import Divider from "../../components/layout/Divider"
import {formatReadableDate} from "../../utils/datetime.utils"
import GoogleCalendars from "../../assets/images/GoogleCalendars.svg"
import Popup from "reactjs-popup"
import ChangeOOOCalendarPopup from "./components/ChangeCalendarPopup"
import Typography from "../../components/typography/Typography"
import DescriptionList from "../../components/typography/DescriptionList"


const GoogleIntegrations = () => {

    const {hasPermission} = useSelector((state) => state.profile)

    const {data: integration, isLoading: integrationLoading, isFetching: integrationFetching} = useGetGoogleCalendarIntegrationQuery()
    const [setupGoogleCalendarIntegration, {isLoading: setupLoading}] = useSetupGoogleCalendarIntegrationMutation()
    const [removeGoogleCalendarIntegration, {isLoading: removeLoading}] = useRemoveGoogleCalendarIntegrationMutation()

    const isLoading = integrationLoading || integrationFetching || removeLoading || setupLoading
    const [userMessage, setUserMessage] = useState(null)

    const [selectedCalendar, setSelectedCalendar] = useState()

    const [changeCalendarModal, setChangeCalendarModal] = useState(false)

    const {data: calendars, isLoading: calendarsLoading} = useGetGoogleCalendarsQuery({}, {skip: !integration})

    const calendarsOptions = useMemo(() => calendars?.map((calendar) => ({label: calendar.name, value: calendar.id})), [calendars])

    useEffect(() => {
        if (integration && calendarsOptions) {
            setSelectedCalendar(calendarsOptions.find((c) => c.value === integration.ooo_calendar_id))
        }
    }, [calendarsOptions, integration])

    const connect = async () => {
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: process.env.REACT_APP_GOOGLE_INTEGRATION_CLIENT_ID,
            scope: "https://www.googleapis.com/auth/calendar",
            access_type: "offline",
            ux_mode: "popup",
            callback: async (response) => {
                try {
                    await setupGoogleCalendarIntegration(response.code).unwrap()
                    setUserMessage({message: "Connected the integration to Google Calendar", code: SUCCESS_CODE})
                } catch (error) {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                }
            }
        })
        client.requestCode()
    }
    const remove = async () => {
        try {
            await removeGoogleCalendarIntegration().unwrap()
            setUserMessage({message: "Integration removed", code: SUCCESS_CODE})
        } catch (error) {
            setUserMessage({message: error.message, code: ERROR_CODE})
        }
    }

    const onCalendarChange = async (option) => {
        setSelectedCalendar(option)
        setChangeCalendarModal(true)
    }

    return (
        <>
            <Helmet>
                <title>Google Integration - Newsoft Inside</title>
            </Helmet>

            {userMessage && <AlertMessage setMessage={setUserMessage} {...userMessage}/>}

            <Popup open={changeCalendarModal} onClose={() => setChangeCalendarModal(false)} modal>
                <ChangeOOOCalendarPopup
                    calendarId={selectedCalendar?.value}
                    calendarName={selectedCalendar?.label}
                    setUserMessage={setUserMessage}
                    close={() => setChangeCalendarModal(false)}
                />
            </Popup>

            <Card className="google-integration">
                <div className="google-integration-header">
                    <div className="google-integration-header-title-container">
                        <div className="google-integration-header-title-container-icon">
                            <img src={GoogleCalendars} alt="Google Calendars"/>
                        </div>
                        <div>
                            <Typography variant="header">Google Calendar</Typography>
                            <Typography variant="supporting1">Integrate your company’s calendar to mark “OOO”</Typography>
                        </div>
                    </div>
                    {!integration && (
                        <SmallButton
                            loading={setupLoading}
                            disabled={isLoading || !hasPermission(PERMISSIONS.WRITE_INTEGRATIONS)}
                            onClick={connect}
                        >
                            Connect
                        </SmallButton>
                    )}
                    {integration && (
                        <SmallButton
                            loading={removeLoading}
                            disabled={isLoading || !hasPermission(PERMISSIONS.WRITE_INTEGRATIONS)}
                            onClick={remove}
                        >
                            Remove
                        </SmallButton>
                    )}
                </div>

                <Divider/>

                <div className="google-integration-body">
                    <div className="google-integration-body-description">
                        <Typography variant="leading">Description</Typography>
                        <DescriptionList data={[
                            {
                                title: "On behalf of users, Google Calendar can:",
                                items: [
                                    "View information about a user’s identity, granted by 2 team members",
                                    "Edit a user’s profile information and status, granted by 2 team members",
                                ],
                            },
                            {
                                title: "On behalf of the app, Google Calendar can:",
                                items: [
                                    "Send messages as @google_calendar",
                                    "Add shortcuts and/or slash commands that people can use",
                                    "Start direct messages with people",
                                    "View the name, email domain, and icon for workspaces Google Calendar is connected to",
                                    "View people in a workspace",
                                    "View email addresses of people in a workspace",
                                ],
                            },
                        ]}/>
                    </div>
                    <Divider/>

                    {integration && (
                        <>
                            <div className="google-integration-body-authorization">
                                <div>
                                    <Typography variant="leading">Your authorization</Typography>
                                    <Typography
                                        variant="supporting"><span style={{"text-decoration": "underline"}}>{integration.user_email}</span> on {formatReadableDate(integration.authorization_date)}</Typography>
                                </div>
                                <SmallButton
                                    btnType="secondary"
                                    loading={setupLoading}
                                    disabled={(!setupLoading && isLoading) || !hasPermission(PERMISSIONS.WRITE_INTEGRATIONS)}
                                    onClick={connect}
                                >
                                    Change
                                </SmallButton>
                            </div>

                            <Divider/>

                            <div className="google-integration-body-ooo-calendar">
                                <div>
                                    <Typography variant="leading">&#34;OOO&#34; Calendar</Typography>
                                    <Typography variant="supporting">Select which calendar you want to use as a global “OOO” calendar for
                                        your
                                        company</Typography>
                                </div>
                                <div className="google-integration-body-ooo-calendar-dd">
                                    <DDList
                                        placeholder="Choose a calendar"
                                        listOptions={calendarsOptions}
                                        value={selectedCalendar}
                                        onChange={onCalendarChange}
                                        isDisabled={calendarsLoading || isLoading}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </>)

}

export default GoogleIntegrations
