
export const getSource = (log) => {
    return log.user_name || log.user_id || "System"
}


export const getAction = (log) => {
    return {
        created: "Created",
        updated: "Updated",
        archived: "Archived",
        deactivated: "Deactivated",
        claimed: "Claimed",
        assigned: "Assigned",
        unassigned: "Unassigned",
        approved: "Approved",
        declined: "Declined",
        cancelled: "Cancelled",
        submitted: "Submitted",
        completed: "Completed",
        deleted: "Deleted",
    }[log.event_type]
}

export const getObject = (log) => {
    return {
        employee: "Employee",
        salary: "Salary",
        employee_position: "Employee Position",
        salary_overtime: "Overtime",
        bonus: "Bonus",
        performance_review: "Performance Review",
        time_record: "Time Record",
        time_off_request: "Time Off Requests",
        role: "Role",
        project: "Project",
        client: "Client",
        equipment: "Equipment",
        equipment_category: "Equipment Category",
        shop_order: "Shop Order",
        shop_product: "Shop Product",
        shop_product_label: "Shop Product Label",
        shop_product_category: "Shop Product Category",
        coupon: "Coupon",
        coins: "Coins",
        calendar: "Calendar",
        national_dayoffs: "National Day-off",
        global_settings: "Global Settings",
        user_settings: "User Settings",

    }[log.object_type]
}
