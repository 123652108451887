import React, {useState} from "react"

import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import DDList from "../../../../toolkits/DDList/DDList"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import {
    useAssignCalendarToUsersMutation,
    useGetPublicHolidayCalendarsQuery
} from "../../../../api/publicHolidayCalendars"


const UpdateCalendarPopup = ({
    user,
    close,
    receiveUserInfo,
    setUserMessage,
}) => {
    const [currentCalendar, setCurrentCalendar] = useState(user.active_calendar)
    const [assignCalendarToUsers] = useAssignCalendarToUsersMutation()
    const { data: result } = useGetPublicHolidayCalendarsQuery()
    const calendars = result?.items || []

    const handleSubmit = () => {
        const data = {
            user_ids: [user.id],
        }

        assignCalendarToUsers({calendar_id: currentCalendar.id, data})
            .unwrap()
            .then(() => {
                receiveUserInfo()
                close(false)
                setUserMessage({message: "Calendar was successfully assigned!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup className="edit-calendar-popup" text_title="Edit Calendar">
            <DDList
                text_label="Calendar"
                placeholder=""
                defaultValue={({value: currentCalendar, label: currentCalendar.name})}
                listOptions={calendars?.map((calendar) => ({value: calendar, label: calendar.name}))}
                onChange={calendar => setCurrentCalendar(calendar.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">    
                    <SmallButton btnType={"secondary"} onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={currentCalendar.id === user.active_calendar.id}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UpdateCalendarPopup
