export const PERMISSIONS = {
    READ_AUDIT_LOGS: "read_audit_logs",

    /* USER */
    READ_USER: "read_user",
    WRITE_USER: "write_user",
    WRITE_OWN_USER: "write_own_user",
    WRITE_ARCHIVED_USER: "write_archived_user",
    DELETE_USER: "delete_user",

    READ_ENGLISH_LEVEL: "read_english_level",
    READ_OWN_ENGLISH_LEVEL: "read_own_english_level",

    /* REPORTS */
    READ_REPORTS: "read_reports",
    READ_OWN_REPORTS: "read_own_reports",

    /* ROLES */
    READ_ROLE: "read_role",
    READ_OWN_ROLE: "read_own_role",
    WRITE_ROLE: "write_role",

    /* PERMISSIONS */
    READ_PERMISSIONS: "read_permissions_permission",

    /* EQUIPMENT & EQUIPMENT CATEGORY */
    READ_EQUIPMENT: "read_equipment",
    READ_OWN_EQUIPMENT: "read_own_equipment",
    WRITE_EQUIPMENT: "write_equipment",

    /* PROJECTS */
    READ_PROJECT: "read_project",
    READ_OWN_PROJECT: "read_own_project",
    WRITE_PROJECT: "write_project",
    ASSIGN_PROJECT: "assign_project",

    /* PERFORMANCE REVIEWS */
    READ_PERFORMANCE_REVIEW: "read_performance_review",
    READ_OWN_PERFORMANCE_REVIEW: "read_own_performance_review",
    WRITE_PERFORMANCE_REVIEW: "write_performance_review",

    /* CLIENT */
    READ_CLIENT: "read_client",
    WRITE_CLIENT: "write_client",

    /* CALENDAR EVENTS */
    READ_CALENDAR_EVENTS: "read_public_calendar_events",
    WRITE_CALENDAR_EVENTS: "write_public_calendar_events",

    /* SALARY */
    READ_SALARY: "read_salary",
    READ_OWN_SALARY: "read_own_salary",
    WRITE_SALARY: "write_salary",

    /* TIME RECORDS */
    READ_TIME_RECORD: "read_time_record",
    READ_OWN_TIME_RECORD: "read_own_time_record",
    WRITE_TIME_RECORD: "write_time_record",
    WRITE_OWN_TIME_RECORD: "write_own_time_record",
    WRITE_OWN_DAY_OFF: "write_own_dayoff",

    /* VACATION REQUESTS */
    READ_TIME_OFF_REQUEST: "read_time_off_request",
    READ_OWN_TIME_OFF_REQUEST: "read_own_time_off_request",
    WRITE_TIME_OFF_REQUEST: "write_time_off_request",
    WRITE_OWN_TIME_OFF_REQUEST: "write_own_time_off_request",

    /* VACATION BALANCE */
    READ_TIME_OFF_BALANCE: "read_time_off_balance",
    READ_OWN_TIME_OFF_BALANCE: "read_own_time_off_balance",
    WRITE_TIME_OFF_BALANCE: "write_time_off_balance",

    /* NOTIFICATIONS */
    READ_NOTIFICATION: "read_notification",
    READ_DASHBOARD_NOTIFICATION: "read_dashboard_notification",

    /* EMPLOYEE POSITION */
    READ_EMPLOYEE_POSITION: "read_employee_position",
    WRITE_EMPLOYEE_POSITION: "write_employee_position",

    /* SETTINGS */
    READ_SETTINGS: "read_settings",
    WRITE_SETTINGS: "write_settings",

    /* BONUSES */
    READ_BONUS: "read_bonus",
    READ_OWN_BONUS: "read_own_bonus",
    WRITE_BONUS: "write_bonus",

    /* NEWCOINS */
    READ_NEWCOINS: "read_newcoins",
    READ_OWN_NEWCOINS: "read_own_newcoins",
    WRITE_NEWCOINS: "write_newcoins",
    WRITE_OWN_NEWCOINS: "write_own_newcoins",

    /* PAYMENT REPORTS */
    READ_PAYMENT_REPORTS: "read_payment_reports",

    /* ONBOARDING */
    READ_OWN_ONBOARDING: "read_own_onboarding",
    WRITE_OWN_ONBOARDING: "write_own_onboarding",
    READ_ONBOARDING: "read_onboarding",
    WRITE_ONBOARDING: "write_onboarding",

    /* SHOP */
    READ_SHOP: "read_shop",
    WRITE_SHOP: "write_shop",
    READ_OWN_PURCHASE_REQUEST: "read_own_purchase_request",
    READ_PURCHASE_REQUEST: "read_purchase_request",
    WRITE_OWN_PURCHASE_REQUEST: "write_own_purchase_request",
    WRITE_PURCHASE_REQUEST: "write_purchase_request",

    /* COUPONS */
    READ_COUPONS: "read_coupons",
    WRITE_COUPONS: "write_coupons",
    READ_OWN_COUPONS: "read_own_coupons",

    /* INTEGRATIONS */
    READ_INTEGRATIONS: "read_integrations",
    WRITE_INTEGRATIONS: "write_integrations",
}
/**
 * If user has at least 1 permission from this list,
 * then he has access to the Dashboard
 **/
export const DASHBOARD_PERMISSIONS = [
    PERMISSIONS.READ_REPORTS,
    PERMISSIONS.READ_EQUIPMENT,
    PERMISSIONS.READ_PERFORMANCE_REVIEW,
    PERMISSIONS.READ_PROJECT,
    PERMISSIONS.READ_ROLE,
    PERMISSIONS.READ_CLIENT,
    PERMISSIONS.READ_CALENDAR_EVENTS,
    PERMISSIONS.READ_TIME_RECORD,
    PERMISSIONS.READ_TIME_OFF_REQUEST,
    PERMISSIONS.READ_TIME_OFF_BALANCE,
    PERMISSIONS.READ_EMPLOYEE_POSITION,
    PERMISSIONS.READ_PAYMENT_REPORTS,
    PERMISSIONS.READ_NEWCOINS,
    PERMISSIONS.WRITE_SHOP // Allows to see the Products card
]
export const USER_PROFILE_PERMISSIONS = [
    PERMISSIONS.READ_SALARY,
    PERMISSIONS.READ_EQUIPMENT,
    PERMISSIONS.READ_PERFORMANCE_REVIEW,
    PERMISSIONS.READ_PROJECT,
    PERMISSIONS.READ_ROLE,
    PERMISSIONS.READ_TIME_OFF_REQUEST,
    PERMISSIONS.READ_REPORTS,
    PERMISSIONS.READ_TIME_OFF_BALANCE,
    PERMISSIONS.WRITE_ARCHIVED_USER,
]