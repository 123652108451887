import "./SingleDatePicker.scss"

import React, {useEffect, useMemo, useRef, useState} from "react"
import DatePicker from "react-multi-date-picker"
import InputIcon from "react-multi-date-picker/components/input_icon"
import TimePicker from "react-multi-date-picker/plugins/time_picker"

import {useCalendar} from "../../hooks"
import {CustomButton} from "./CustomButton"
import {formatDate, validateDate} from "../../utils/datetime.utils"
import {
    DAYS_PER_WEEK, DD_MM,
    DD_MM_YYYY,
    DD_MM_YYYY_HH_MM,
    DD_MM_YYYY_HH_MM_SS, HH_MM, HH_MM_SS,
    MAX_DAYS_PER_MONTH,
    MAX_YEAR,
    MONTHS_IN_YEAR
} from "../../constants/other"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import useCurrentUserId from "../../hooks/useCurrentUserId"
import moment from "moment/moment"
import {endOfMonth, startOfMonth} from "date-fns"
import _ from "lodash"


const getFormat = (minutes, seconds, years) => {
    if (!minutes && !seconds && !years) {
        return DD_MM
    } else if (!minutes && !seconds) {
        return DD_MM_YYYY
    } else if (minutes && !seconds) {
        return DD_MM_YYYY_HH_MM
    } else if (seconds) {
        return DD_MM_YYYY_HH_MM_SS
    }
}

const getTimeFormat = (seconds) => {
    return seconds ? HH_MM_SS : HH_MM
}

const DateInputIcon = ({format, openCalendar, value, handleValueChange, reset, ...props}) => {
    const [valid, setValid] = useState(true)
    const {datepickerRef, className, defaultValue} = props
    if (!value || value === "") {
        if (!reset) {
            value = formatDate(defaultValue, format)
            value = value === "Invalid date" ? null : value
        }
    }
    if (Array.isArray(value)) {
        value = value[0]
    }

    const onChange = (e) => {
        const [day, month, year] = e.target.value.split(".").map((token) => token ? parseInt(token) : null)
        if ((day && day > MAX_DAYS_PER_MONTH) || (month && month > MONTHS_IN_YEAR) || (year && year > MAX_YEAR)) {
            return false
        }
        handleValueChange(e)
        setValid(validateDate(e.target.value, format))
    }

    const handleResetClick = (event) => {
        event.preventDefault()
        handleValueChange({target: {value: ""}})
    }

    useEffect(() => {
        if (reset && defaultValue) {
            value = formatDate(defaultValue, format)
            handleValueChange({target: {value: value}})
        }
    }, [])

    useEffect(() => {
        setValid(value ? validateDate(value, format) : true)
    }, [value])

    return (
        <label className="single-date-input-wrapper-label">
            <InputIcon
                placeholder={format}
                className={className || " "}
                onClick={() => datepickerRef.current?.isOpen ? datepickerRef.current.closeCalendar() : openCalendar()}
                value={value}
                onChange={onChange}
            />
            {value && reset && valid &&
                <CloseRoundedIcon
                    onClick={handleResetClick}
                    className="reset-input-btn"
                />
            }
            {!valid && <span className="t-s3 validation-error">*Enter valid date</span>}
        </label>
    )
}

const SingleDatePicker = ({
    label,
    defaultValue,
    onChange,
    className = "",
    withPublicDayOffs,
    value,
    minDate,
    maxDate,
    disableDays,
    reset,
    onCalendarBoundariesChange = null,
    minutes = false,
    seconds = false,
    years = true,
    calendarPosition = "bottom",
    ...props
}) => {
    const format = getFormat(minutes, seconds, years)

    const userId = useCurrentUserId() // This doesn't really belong here but let's change it later
    const [calendarDateBoundaries, setCalendarDateBoundaries] = useState({
        startDate: formatDate(moment(startOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
        endDate: formatDate(moment(endOfMonth(new Date)).add(DAYS_PER_WEEK, "days")),
    })
    const {fillCalendar} = useCalendar({userId, startDate: calendarDateBoundaries.startDate, endDate: calendarDateBoundaries.endDate})
    useEffect(() => onCalendarBoundariesChange?.(calendarDateBoundaries), [calendarDateBoundaries])

    const [selectedDate, setSelectedDate] = useState(defaultValue)
    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            ref.current.closeCalendar()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const debouncedChange = useMemo(() => _.debounce(onChange, 300), [onChange])

    const onSelectedDateChanged = (date) => {
        const newDate = date?.toDate()

        if (date) {
            const dayChanged = selectedDate?.toDateString() !== newDate.toDateString()
            setSelectedDate(newDate)

            if (!dayChanged) {
                debouncedChange(newDate)
            } else {
                debouncedChange.cancel()
                onChange(newDate)
            }
        } else {
            setSelectedDate(null)
            onChange(null)
        }

    }

    return (
        <div className="single-date-input">
            {label && <p className="single-date-input-label">{label}</p>}
            <div className="single-date-input-wrapper">
                <DatePicker
                    ref={ref}
                    mapDays={({date, today, selectedDate, isSameDate}) => {
                        return fillCalendar(date, today, selectedDate, isSameDate, new Date(), disableDays, props?.showTimeOffsAndLoggedDays, withPublicDayOffs)
                    }}
                    render={<DateInputIcon format={format} className={className} defaultValue={selectedDate} datepickerRef={ref}
                        reset={reset}/>}
                    format={format}
                    value={value ? value : formatDate(selectedDate, format)}
                    onChange={onSelectedDateChanged}
                    renderButton={<CustomButton
                        onCalendarBoundariesChange={(startDate, endDate) => setCalendarDateBoundaries({startDate, endDate})}/>}
                    calendarPosition={calendarPosition}
                    weekStartDayIndex={1}
                    minDate={minDate}
                    maxDate={maxDate}
                    plugins={[<TimePicker key="time-picker-plugin" position="bottom" format={getTimeFormat(seconds)}
                        hideSeconds={!seconds}/>]}
                    {...props}
                />
            </div>
        </div>
    )
}

export default SingleDatePicker