import React, {useContext, useState} from "react"
import Protected from "../../../../components/Protected/Protected"
import {PERMISSIONS} from "../../../../constants/permissions"
import Popup from "reactjs-popup"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import UpdateCalendarPopup from "../Popups/UpdateCalendarPopup"

const CalendarsCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const [showUpdateCalendarPopup, setShowUpdateCalendarPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div className={`profile-right-calendar ${hasPermission(PERMISSIONS.READ_CALENDAR_EVENTS) ? "clickable" : ""} ${theme.profileCardHover}`}
                onClick={() => setShowUpdateCalendarPopup(true)}>
                <span className="t-h3 profile-font-leading">Calendar</span>
                <div className="t-h3 profile-right-calendar-content">
                    <span className="t-s6">{user?.active_calendar?.name}</span>
                </div>

                <div className="profile-info-card-links">
                    <Protected permissions={[PERMISSIONS.WRITE_CALENDAR_EVENTS]}>
                        <PenIcon onClick={() => setShowUpdateCalendarPopup(true)} className="profile-info-card-pen-icon clickable"/>
                    </Protected>
                </div>
            </div>

            <Popup open={showUpdateCalendarPopup} closeOnDocumentClick={false} onClose={() => setShowUpdateCalendarPopup(false)} modal>
                {close =>
                    <UpdateCalendarPopup
                        user={user}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default CalendarsCard