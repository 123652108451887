import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const googleIntegrationApi = createApi({
    reducerPath: "googleIntegrationApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["GoogleCalendarIntegration", "GoogleCalendarIntegrationCalendars"],
    endpoints: (builder) => ({
        getGoogleCalendarIntegration: builder.query({
            query: (params) => ({
                path: "integrations/google-calendar/",
                params,
            }),
            providesTags: ["GoogleCalendarIntegration"]
        }),
        setupGoogleCalendarIntegration: builder.mutation({
            query: (code) => ({path: "integrations/google-calendar/", method: "POST", data: { code }}),
            invalidatesTags: ["GoogleCalendarIntegration", "GoogleCalendarIntegrationCalendars"]
        }),
        removeGoogleCalendarIntegration: builder.mutation({
            query: () => ({path: "integrations/google-calendar/", method: "DELETE"}),
            invalidatesTags: ["GoogleCalendarIntegration"]
        }),
        getGoogleCalendars: builder.query({
            query: (params) => ({
                path: "integrations/google-calendar/calendars",
                params,
            }),
            providesTags: ["GoogleCalendarIntegrationCalendars"]
        }),
        setOOOCalendar: builder.mutation({
            query: (id) => ({path: "integrations/google-calendar/set-ooo-calendar", method: "POST", data: { id }}),
            invalidatesTags: ["GoogleCalendarIntegration"]
        }),
    })
})

export const {
    useGetGoogleCalendarIntegrationQuery,
    useRemoveGoogleCalendarIntegrationMutation,
    useSetupGoogleCalendarIntegrationMutation,
    useGetGoogleCalendarsQuery,
    useSetOOOCalendarMutation,
} = googleIntegrationApi
