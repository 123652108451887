import "../../layouts/Navs/NavsLayout.scss"
import "./index.scss"

import React, {useEffect, useState} from "react"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import {useMediaQuery} from "react-responsive"

import {generateCancelToken} from "../../http"

import {receiveEmployees, receiveNextPageEmployees} from "./actions/employeesActions"

import Protected from "../../components/Protected/Protected"
import EmployeesList from "./components/EmployeesList"
import {EmployeesFilters} from "./components/EmployeesFilters"
import AddEmployeePopup from "./components/AddEmployeePopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {getDraft, saveDraft} from "../../utils/localstorage.utils"

import {ProfileRoute} from "../../routes"
import {useCustomHistory} from "../../hooks/useCustomHistory"

import {
    DESKTOP_WIDTH,
    EMPLOYEES_FILTERS,
    PAGE_SIZE,

} from "../../constants/other"

import CloseButtonIcon from "@mui/icons-material/Close"
import {PERMISSIONS} from "../../constants/permissions"


/**
 * @desc Module "Employees Page".
 * @return {JSX.Element} - module that includes a page, with user list, and possibily to change them (if manager/admin).
 */
const Employees = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const history = useCustomHistory()
    const auth = useSelector((state) => state.auth)

    const {
        loading,
        employees,
        total,
    } = useSelector((state) => state.employees)
    
    const [cancelToken, setCancelToken] = useState(null)

    const [userMessage, setUserMessage] = useState(null)
    const [showDraftCreateUser, setShowDraftCreateUser] = useState(Object.values(getDraft(auth.user.uid)?.createUser ?? {}).length)

    const [find, setFind] = useState(EMPLOYEES_FILTERS.UNARCHIVE)
    const [searchKey, setSearchKey] = useState()
    const [addEmployeePopup, setAddEmployeePopup] = useState(false)

    /**
     * @desc [Function] Function for choosing profile route.
     * @returns {function}
     */

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const clearDraftUser = () => {
        saveDraft(auth.user.uid, {createUser: {}})
        setShowDraftCreateUser(false)
    }

    const getParams = (page) => {
        page = page ? page : currentPage

        let params = {
            size: PAGE_SIZE,
            page,
            query: searchKey,
            archived: find === EMPLOYEES_FILTERS.ARCHIVE,
        }
        return params
    }

    const getEmployees = (page) => {
        const params = getParams(page)

        dispatch(
            receiveEmployees(params, generateCancelToken(cancelToken, setCancelToken))
        )

        setCurrentPage(page + 1)
    }

    const getNextEmployees = () => {
        const params = getParams()
        dispatch(receiveNextPageEmployees(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getEmployees(1)
    }, [find])

    useEffect(() => {
        if (searchKey !== null && searchKey !== undefined) {
            getEmployees(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Employees - Newsoft Inside</title>
            </Helmet>
            <div className="employees">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} {...userMessage}/>
                    }
                </div>
                <div className="employees-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <div className="employees-header-right">
                        <Protected permissions={[PERMISSIONS.WRITE_USER]}>
                            <div className="employees-header-right-add">
                                {(isDesktop && showDraftCreateUser) ?
                                    <div className="employees-header-right-add-draft">
                                        <div>
                                            <Popup
                                                trigger={<span className="t-b2 clickable">use employee draft</span>}
                                                closeOnDocumentClick={false}
                                                modal
                                            >
                                                {close => (
                                                    <>
                                                        <AddEmployeePopup
                                                            employee={getDraft(auth.user.uid)?.createUser}
                                                            setShowDraftCreateUser={setShowDraftCreateUser}
                                                            refreshTable={() => getEmployees(1)}
                                                            setUserMessage={setUserMessage}
                                                            close={close}
                                                        />
                                                    </>
                                                )}
                                            </Popup>
                                            <CloseButtonIcon className="clear-draft-icon clickable" onClick={clearDraftUser}/>
                                        </div>
                                    </div> : <></>
                                }
                                <div className="employees-header-right-add-button">
                                    <SmallButton onClick={() => setAddEmployeePopup(true)}> ADD EMPLOYEE </SmallButton>
                                </div>
                            </div>
                        </Protected>
                        <Protected permissions={[PERMISSIONS.WRITE_ARCHIVED_USER]}>
                            <EmployeesFilters setFind={setFind}/>
                        </Protected>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextEmployees}
                    hasMore={!loading && employees.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={employees.length}
                    scrollableTarget={"main-content"}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="employees-content">
                            <EmployeesList
                                users={employees}
                                profileClick={profileClick}
                                setUserMessage={setUserMessage}
                            />
                        </div>}
                </InfiniteScroll>
            </div>

            <Popup open={addEmployeePopup} closeOnDocumentClick={false} onClose={() => setAddEmployeePopup(false)} modal>
                {close => (
                    <AddEmployeePopup
                        setShowDraftCreateUser={setShowDraftCreateUser}
                        refreshTable={() => getEmployees(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>
    )
}

export default Employees
