import "./NavsLayout.scss"

import React, {useContext, useEffect, useState, useMemo} from "react"
import ReactGA from "react-ga"
import Popup from "reactjs-popup"
import UserImage from "../../assets/images/user_photo.png"
import ArrowLeft from "@mui/icons-material/KeyboardArrowLeft"


import {Outlet, matchPath, useLocation} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"

import {
    EmployeesRoute,
    PersonalPurchaseHistoryRoute,
    ProfileRoute,
    ShopRoute,
    UserProfileRoute,
    ViewProductRoute
} from "../../routes"

import {clearState} from "./actions/navigationActions"
import {
    receiveUserNotifications,
    updateUnreadNotificationsCount
} from "../../pages/Notifications/actions/userNotificationsActions"
import {
    generateRandomSmile,
    receiveMyInfo,
    successGetMe
} from "../../pages/Profile/actions/userActions"
import {markAsReadUserNotifications} from "../../pages/Notifications/api"

import {useCustomHistory} from "../../hooks/useCustomHistory"

import Protected from "../../components/Protected/Protected"
import {LogoutPopup} from "../../pages/Auth/components/LogoutPopup"
import UserNotificationsPopup from "../../pages/Notifications/components/userNotificationsPopup"
import PermissionsConflictPopup from "../../pages/Roles/components/PermissionsConflictPopup"

import PageContainer from "../../components/containers/PageContainer"
import ContentWrapper from "../../components/containers/ContentWrapper"
import MainContent from "../../components/containers/MainContent"
import Toolbar from "../../components/containers/Toolbar"
import Menu from "./Menu"
import MenuButton from "./MenuButton"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {isAdmin} from "../../utils/permission.utils"
import {getTitleOfPage, isTrue} from "../../utils/other.utils"
import {
    disableViewAsMemberMode,
    enableViewAsMemberMode,
    getUserViewedAs,
} from "../../utils/localstorage.utils"

import {
    INFO_ALERT,
    LIMIT_NOTIFICATIONS_FOR_BELL,
    MOBILE_WIDTH, SETTINGS, TABLET_WIDTH,
} from "../../constants/other"

import GreetingsPopup from "../../components/Greetings"

import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded"
import InfoIcon from "@mui/icons-material/Info"
import {ONBOARDING_STATUS} from "../../constants/onboarding"
import {PERMISSIONS} from "../../constants/permissions"
import {setOnboardingAnimation} from "../../pages/Profile/actions/onboardingActions"
import LogoutIcon from "@mui/icons-material/Logout"
import { ThemeContext } from "../../styles/theme-context"
import SettingsEnabled from "../../components/SettingsEnabled/SettingsEnabled"
import Newcoin from "../../toolkits/Newcoin"
import NewcoinsUsagePopup from "../../pages/Profile/components/Popups/NewcoinsUsagePopup"
import {SensitiveInfo} from "../../components/SensitiveInfo/SensitiveInfo"
import useCoinsBalance from "../../hooks/useCoinsBalance"
import {useGetUserOnboardingsQuery} from "../../api/onboardings"


const NavsLayout = () => {
    const history = useCustomHistory()
    const location = useLocation()
    const userViewedAs = getUserViewedAs()
    const auth = useSelector((state) => state.auth)
    const userId = useMemo(() => auth?.user?.uid, [auth])

    const dispatch = useDispatch()

    const isTablet = useMediaQuery({maxWidth: TABLET_WIDTH})
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    
    const [expanded, setExpanded] = useState(false)
    const [titleOfPage, setTitleOfPage] = useState(null)
    const [showNotificationsPopup, setShowNotificationsPopup] = useState(false)
    const [showGreetingsPopup, setShowGreetingsPopup] = useState(true)
    const [showNewcoinsUsagePopup, setShowNewcoinsUsagePopup] = useState(false)
    const [notificationsIds, setNotificationsIds] = useState([])
    const {data: userOnboarding} = useGetUserOnboardingsQuery(userId)
    const newcoinsEnabled = useSelector((state) => state.settings.newcoinsEnabled)
    const [showNewcoins, setShowNewcoins] = useState(false)
    const {balance} = useCoinsBalance({
        userId: userViewedAs ? userViewedAs.user_id : userId,
        skip: !(isTrue(newcoinsEnabled.value) && showNewcoins)
    })

    const {hasPermission} = useSelector((state) => state.profile)

    const countEmployees = useSelector((state) => state.employees.total)
    const product = useSelector((state) => state.viewProduct.product)
    const {unreadNotificationsCount} = useSelector((state) => state.userNotifications)
    const {currentUser, myInfo} = useSelector((state) => state.profile)
    const {navigationStack} = useSelector((state) => state.navigation)
    const [message, setMessage] = useState("")
    const [showPermissionsConflictPopup, setShowPermissionsConflictPopup] = useState(false)
    const [extraPermissions, setExtraPermissions] = useState([])

    const [isMyOnboarding, setIsMyOnboarding] = useState(true)
    const theme = useContext(ThemeContext)

    const finishOnboardingClick = () => {
        dispatch(setOnboardingAnimation(true))
        history.push(ProfileRoute.path)
    }

    const markAsReadNotifications = () => {
        const data = {
            user_id: userId,
            notification_ids: notificationsIds,
        }

        if (notificationsIds.length > 0) {
            markAsReadUserNotifications(data).then(() => {
                dispatch(receiveUserNotifications(userId))
                setNotificationsIds([])
            })
        }
    }

    useEffect(() => {
        if (userOnboarding?.user) {
            userId === userOnboarding.user.id
                ? setIsMyOnboarding(true)
                : setIsMyOnboarding(false)
        }
    }, [userOnboarding, userId])


    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
        setShowNewcoins(
            [
                ViewProductRoute.path,
                PersonalPurchaseHistoryRoute.path,
                ShopRoute.path,
            ].some((path) => matchPath({ path }, location.pathname)))
    },[history.location.pathname])


    useEffect(() => {
        const title = getTitleOfPage(history.location.pathname)
        title === EmployeesRoute.name ? setTitleOfPage(`${EmployeesRoute.name} (${countEmployees})`) : setTitleOfPage(title)
        if (!title && history.location.state?.title) {
            setTitleOfPage(history.location.state?.title)
        }
        if (history.location.pathname?.startsWith("/product/") && !history.location.state?.title && !title) {
            setTitleOfPage(product.name)
        }

        dispatch(generateRandomSmile(theme))
    }, [countEmployees, product, history.location])

    const viewAsMember = () => {
        const myPermissionNames = myInfo.permissions.map((permission) => permission.permission_name)
        const extraPermissions = currentUser.permissions.filter(x => !myPermissionNames.includes(x.permission_name))
        setExtraPermissions(extraPermissions)

        if(extraPermissions.length > 0){
            setShowPermissionsConflictPopup(true)
        } else {
            history.push(ProfileRoute.path, {}, ProfileRoute.path)
            enableViewAsMemberMode(currentUser)
            dispatch(clearState())
            dispatch(successGetMe({
                ...currentUser,
                roles: currentUser.roles,
                permissions: currentUser.permissions
            }))
        }
    }

    const exitViewAsMember = () => {
        disableViewAsMemberMode()
        dispatch(receiveMyInfo())
        dispatch(clearState())
        setMessage("View as member was closed")
    }

    return (
        (isTablet && expanded) ? <Menu expanded={expanded} setExpanded={setExpanded}/> :
            <PageContainer>
                {message &&
                        <AlertMessage setMessage={setMessage} message={message} type={INFO_ALERT}/>
                }
                {!isTablet && <Menu activeNavs={showNotificationsPopup} expanded={expanded} setExpanded={setExpanded}/>}
                <ContentWrapper>
                    <Toolbar activeToolbar={showNotificationsPopup} theme={theme}>
                        {(!isAdmin() && isTablet && !expanded) && <MenuButton onClick={() => setExpanded(true)}/>}
                        
                        <div className={`main-title${isAdmin() ? "-admin" : ""} ${isMobile ? "t-s3" : "t-h2"}`}>
                            {navigationStack.get(history.location.state?.activeTab)?.length > 1 && !isMobile &&
                                <ArrowLeft
                                    onClick={() => history.goBack()}
                                    className="main-title-back-button"/>
                            }
                            <span className="main-title">
                                {titleOfPage}
                            </span>
                        </div>

                        {(!isTablet && userViewedAs) ?
                            <div className="t-s3 navs-header-viewing-as">
                            viewing as:
                                <img
                                    className="navs-header-viewing-as-user-image"
                                    alt="User viewed as"
                                    src={userViewedAs.image_url || UserImage}/>
                                {userViewedAs.full_name}
                                <span className="t-s3 navs-header-viewing-as-exit" onClick={exitViewAsMember}>x</span>
                            </div>
                            : !isTablet &&
                            <Protected permissions={[PERMISSIONS.READ_ROLE, PERMISSIONS.WRITE_ROLE]}>
                                {matchPath({
                                    path: UserProfileRoute.path
                                }, history.location.pathname) &&
                                    <div
                                        className="t-s3 navs-header-view-as-member"
                                        onClick={viewAsMember}
                                    >view as member
                                    </div>
                                }
                            </Protected>
                        }

                        {!isAdmin() ?
                            <>
                                <div className="navs-header-right-nav-span">
                                    {(userOnboarding?.onboarding_status === ONBOARDING_STATUS.IN_PROGRESS || userOnboarding?.onboarding_status === ONBOARDING_STATUS.DECLINED) && (isMyOnboarding || userViewedAs)
                                        ?
                                        (hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING) &&
                                        <div onClick={finishOnboardingClick} className="finish-onboarding-div">
                                            {!isMobile && <span className="t-s6">Finish Onboarding</span>}
                                            <InfoIcon className="finish-onboarding-info-icon"/>
                                        </div>) : null
                                    }
                                    {!isMobile && showNewcoins &&
                                    <Protected permissions={[PERMISSIONS.READ_OWN_NEWCOINS]}>
                                        <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
                                            <div onClick={() => setShowNewcoinsUsagePopup(true)} className="t-s2 newcoins-balance-info">
                                                <SensitiveInfo>
                                                    <span>{balance}</span>
                                                    <Newcoin type={"active"}/>
                                                </SensitiveInfo>
                                            </div>
                                        </SettingsEnabled>
                                    </Protected>}
                                    <Protected permissions={[PERMISSIONS.READ_NOTIFICATION]}>
                                        <div className="notifications-bell-span clickable">
                                            <div className={!showNotificationsPopup ?
                                                "notifications-bell-span-grey" : ""} onClick={() => setShowNotificationsPopup(true)} ><NotificationsRoundedIcon alt="notifications bell"/></div>
                                            {unreadNotificationsCount && !showNotificationsPopup ?
                                                <span
                                                    className={`notifications-bell-span-circle ${isMobile ? "t-b3" : "t-b1"}`}
                                                    onClick={() => setShowNotificationsPopup(true)}
                                                >
                                                    {unreadNotificationsCount < LIMIT_NOTIFICATIONS_FOR_BELL ? unreadNotificationsCount : "9+"}
                                                </span> : <></>
                                            }
                                        </div>
                                    </Protected>

                                </div>
                            </> :
                            <div className="toolbar-exit-button clickable">
                                <Popup trigger={<LogoutIcon className="logout-icon" />} modal>
                                    {close => (
                                        <LogoutPopup close={close}/>
                                    )}
                                </Popup>
                            </div>
                        }
                    </Toolbar>
                    <MainContent>
                        <Outlet/>
                    </MainContent>
                </ContentWrapper>

                <Popup open={showNotificationsPopup} closeOnDocumentClick
                    onClose={() => {
                        dispatch(updateUnreadNotificationsCount({
                            unreadNotificationsCount: Math.max(0, unreadNotificationsCount - notificationsIds.length)
                        }))
                        setShowNotificationsPopup(false)
                        markAsReadNotifications()
                    }} modal>
                    {close => (
                        <UserNotificationsPopup setNotificationsIds={setNotificationsIds} close={close}/>
                    )}
                </Popup>
                <Popup
                    open={showPermissionsConflictPopup}
                    onClose={() => setShowPermissionsConflictPopup(false)}
                    closeOnDocumentClick
                    modal
                >
                    {close => (
                        <PermissionsConflictPopup permissions={extraPermissions} close={close}/>
                    )}
                </Popup>

                <Popup
                    open={showGreetingsPopup}
                    onClose={() => setShowGreetingsPopup(false)}
                    closeOnDocumentClick={false}
                    modal
                >
                    {close => (
                        <GreetingsPopup
                            userId={myInfo.id}
                            firstName={myInfo.first_name}
                            hiringDate={myInfo.hiring_date}
                            birthdate={myInfo.birthdate}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={showNewcoinsUsagePopup} onClose={() => setShowNewcoinsUsagePopup(false)} modal>
                    {close => (
                        <NewcoinsUsagePopup
                            close={close}
                            user={myInfo}
                        />
                    )}
                </Popup>
            </PageContainer>
    )
}

export default NavsLayout
