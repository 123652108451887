import {isEqual, isUndefined, omitBy} from "lodash"
import {useCallback, useEffect, useMemo, useState} from "react"
import {PAGE_SIZE} from "../../constants/other"


export const usePaginatedQuery = (useQuery, {skip, defaultState, transformParams} = {}) => {
    const [mainState, setMainState] = useState({page: 1, sort: {}, params: {}, pageItems: null, ...defaultState})
    const [items, setItems] = useState()


    const queryParams = useMemo(() => ({
        size: PAGE_SIZE,
        page: mainState.page,
        order_by: mainState.sort?.value,
        ...(transformParams ? transformParams(mainState.params) : mainState.params),

    }), [mainState, transformParams])

    const {data, error, isLoading, isFetching, refetch} = useQuery(queryParams, {skip})

    const total = useMemo(() => data?.total, [data])

    const size = useMemo(() => data?.size, [data])

    const maxPage = useMemo(() => total ? Math.ceil(total / size) : null, [size, total])

    useEffect(() => {
        if (isLoading || !mainState.pageItems) { return null }

        setItems(
            Object.entries(mainState.pageItems)
                .sort(([key1], [key2]) => key1 > key2 ? 1 : -1)
                .reduce((acc, [, value]) => [...acc, ...value], [])
        )
    }, [isLoading, mainState.pageItems])

    const loading = useMemo(() => (!error && items === undefined) || isLoading || isFetching, [error, items, isFetching, isLoading])

    const hasMore = useMemo(() => !loading && mainState.page < maxPage, [loading, mainState.page, maxPage])

    const empty = useMemo(() => !loading && items.length === 0, [items, loading])

    const reset = useCallback(() => {
        setMainState({
            page: 1,
            params: {},
            sort: {},
            pageItems: {},
        })
        refetch()
    }, [])

    const loadNextPage = useCallback(() => {
        if (!loading && mainState.page < maxPage) {
            setMainState((prev) => ({...prev, page: prev.page + 1}))
        }
    },
    [mainState.page, maxPage, loading],
    )

    useEffect(() => {
        if (!isLoading && !isFetching && data?.items) {
            setMainState((prev) => ({...prev, pageItems: {...prev.pageItems, [data.page]: data.items}}))
        }
    }, [data, isLoading, isFetching])

    const changeSort = useCallback((newSort) => {
        if (!isEqual(omitBy(newSort, isUndefined), omitBy(mainState.sort, isUndefined))) {
            setMainState((prev) => ({...prev, sort: newSort, page: 1, pageItems: {}}))
        }
    }, [mainState.sort])

    const changeParams = useCallback((params) => {
        const baseParams = omitBy(mainState.params, isUndefined)
        const newParams = omitBy({...baseParams, ...params}, isUndefined)

        if (!isEqual(newParams, baseParams)) {
            setMainState((prev) => ({...prev, params: newParams, page: 1, pageItems: {}}))
        }
    }, [mainState.params])

    return {
        items: items || [],
        params: mainState.params,
        changeParams,
        sort: mainState.sort,
        changeSort,
        page: mainState.page,
        reset,
        total,
        maxPage,
        loadNextPage,
        loading,
        empty,
        hasMore,
        pageItems: mainState.pageItems,
    }
}
